.bannerContainer {
  padding-bottom: 20px;
}

.h1 {
  font-size: 2.3em;
}

.name {
  font-weight: 400;
  top: 6px;
  left: 168px;
  color: #000;
  white-space: nowrap;
}

.description {
  font-weight: 300;
}
