.sectionContainer {
  margin-top: 10vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1.7em;
  text-align: center;
}

.playerWrapper {
  position: relative;
}

.cover {
  padding: 20px;
  max-width: 800px;
  width: 100%;
  position: absolute;
}
